import { Container, Grid, Typography } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow, useGradientStyles } from '@xyo-network/react-shared'
import { ReactNode } from 'react'

interface TwoColumnSectionProps {
  buttonText?: string
  column2children?: ReactNode
  desc?: string
  gradientText?: string
  height?: 'short' | 'medium' | 'tall'
  href?: string
  title: string
  title2?: string
  to?: string
}

export const TwoColumnSection: React.FC<TwoColumnSectionProps> = ({
  title,
  title2,
  buttonText,
  to,
  href,
  gradientText,
  desc,
  height = 'medium',
  column2children,
}) => {
  const { classes } = useGradientStyles()
  return (
    <SectionSpacingRow>
      <Container>
        <FlexGrowCol
          sx={{
            minHeight:
              height == 'tall' ? '800px'
              : height == 'medium' ? '500px'
              : 0,
          }}
          alignItems="center"
        >
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} md={5}>
              <FlexGrowCol alignItems="flex-start">
                <Typography variant="h2" gutterBottom>
                  {title ?
                    <span>{`${title} `}</span>
                  : null}
                  {gradientText ?
                    <span className={classes().heading}> {` ${gradientText}`}</span>
                  : null}
                  {title2 ?
                    <span>{` ${title2}`}</span>
                  : null}
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  {desc}
                </Typography>
                {href || to ?
                  <ButtonEx href={href} to={to} variant="outlined" marginTop={1} target={href ?? '_blank'}>
                    {buttonText ?? 'Learn More'}
                  </ButtonEx>
                : null}
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={5} sx={{ alignSelf: 'stretch' }}>
              {column2children}
            </Grid>
          </Grid>
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}
