import { createContext } from 'react'

interface Props {
  darkMode?: boolean
  developerMode?: boolean
  enableDarkMode?: (enabled: boolean) => void
  enableDeveloperMode?: (show: boolean) => void
}

const SettingsContext = createContext<Props>({})
export default SettingsContext
