import { FacebookCustomEvent, FacebookStandardEvents } from '@xylabs/sdk-react'

export interface FacebookUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

class FacebookEvents<T extends Record<string, unknown>> extends FacebookStandardEvents<T> {
  userClick() {
    return new FacebookCustomEvent<FacebookUserClick | T>('UserClick')
  }
}

export default FacebookEvents
