import { ArrowForwardRounded } from '@mui/icons-material'
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ButtonEx } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

const WhitePaper: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="center" alignItems="center" flexDirection="column">
          <Grid item xs={12} md={6}>
            <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
              XYO solves the crossover between blockchain and location data.
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="h5" fontWeight={500} gutterBottom textAlign="center">
              &quot;With the growing presence of connected, location-reliant technologies, our privacy and safety rely heavily on the accuracy and
              validity of location information. Various attempts have been made to eliminate the need for centralized entities controlling the flow of
              location data, but every attempt has relied on the integrity of the devices collecting this data in the physical world. We propose a
              trustless, cryptographic location network using a novel formulation reliant on a chain of zero-knowledge proofs to establish a high
              degree of data certainty on location information.&quot;
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <ButtonEx endIcon={<ArrowForwardRounded />} href="https://xyo.network/learn">
              Read the white paper
            </ButtonEx>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default WhitePaper
