import { ThemeOptions } from '@mui/material'

import fontFamily from './fontFamily'

export const themeOptions: ThemeOptions = {
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          paddingBottom: 0.5,
          paddingTop: 0.5,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: 'inherit',
        },
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            filter: 'brightness(75%)',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
  },
  palette: {
    info: {
      main: '#2733B1',
    },
    mode: 'light',
    primary: {
      dark: '#010965',
      light: '#EBECFF',
      main: '#384AFD',
    },
    secondary: {
      dark: '#AD8E13',
      light: '#F9D549',
      main: '#FFC91D',
    },
    success: {
      contrastText: '#142B12',
      main: '#76C46D',
    },
    text: {
      primary: '#1F2025',
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 12,
  typography: {
    body1: {
      fontFamily: fontFamily.primary,
      fontSize: '1.1rem',
      fontWeight: 300,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    fontFamily: fontFamily.primary,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    h1: {
      fontFamily: fontFamily.title,
      fontSize: '4rem',
    },
    h2: {
      fontFamily: fontFamily.title,
      fontSize: '2.7rem',
    },
    h3: {
      fontFamily: fontFamily.title,
      fontSize: '2.24rem',
    },
    h4: {
      fontFamily: fontFamily.title,
      fontSize: '2rem',
    },
    h5: {
      fontFamily: fontFamily.title,
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: fontFamily.primary,
      fontSize: '1.25rem',
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: fontFamily.title,
      opacity: '50%',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: fontFamily.title,
      opacity: '50%',
    },
  },
}
