import { Container, Grid, Typography } from '@mui/material'
import { FlexCol, FlexGrowCol } from '@xylabs/sdk-react'
import { SimpleCard } from '@xyo-network/react-card'

import { PlantsWideParallax } from '../../common'

export const ProductsSection: React.FC = () => {
  return (
    <FlexGrowCol
      sx={{
        backgroundPosition: { lg: 'bottom', xs: 'bottom center' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { md: 'cover', xs: 'contain' },
      }}
    >
      <FlexGrowCol sx={{ display: { lg: 'flex', xs: 'none' }, height: { xs: '100%' }, maxHeight: '1000px' }} width="100%" position="absolute">
        <PlantsWideParallax />
      </FlexGrowCol>
      <Container>
        <FlexCol sx={{ maxHeight: { sm: '1000px' }, minHeight: { md: '100vh' }, paddingBottom: { md: 8, xs: 5 } }}>
          <Grid container justifyContent="space-between" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Our Products
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                variant="elevation"
                interactionVariant="button"
                to="/products/xyo-network"
                headline={
                  <Typography variant="h2" textAlign="left" gutterBottom>
                    XYO
                  </Typography>
                }
                desc="XYO is both a cryptographic protocol that improves the certainty and value of data, as well as a self-sovereign data platform allows people to make dApps (decentralized apps) without consensus."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard
                variant="elevation"
                interactionVariant="button"
                to="/products/coin"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },

                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                headline={
                  <Typography variant="h2" textAlign="left" gutterBottom>
                    COIN
                  </Typography>
                }
                desc="Launched in early 2019, COIN is a mobile app enabling over 1 million users to earn digital assets for validating geospatial
                location data when traveling, commuting, jogging or moving about the real world while interacting with each other and enjoying
                in-app challenges."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard
                variant="elevation"
                interactionVariant="button"
                to="/products/xy-find-it"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                headline={
                  <Typography variant="h2" textAlign="left" gutterBottom>
                    XY Find It
                  </Typography>
                }
                desc="The XY4+ Bluetooth Item Finder lets you use your phone to quickly find the things you misplace most - your keys, the remote, or the kids backpacks. Lost your phone? The XY4+ can make it ring, even on silent. Find the things you misplace most like your keys, purse, phone, backpack or more with XY4+."
              />
            </Grid>
          </Grid>
        </FlexCol>
      </Container>
    </FlexGrowCol>
  )
}
