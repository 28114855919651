import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'

const DataismPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePage name="Dataism">
      <BasicHero
        title="Dataism"
        desc="The relationship between a human's data, their physical footprint, and their digital footprint."
        backgroundImage={isMobile ? '' : CommonImages.xyoImage}
        centeredAlways
      />
      <Divider />
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <FlexGrowCol width="100%" alignItems="flex-start">
                <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="left" sx={{ letterSpacing: 2 }} gutterBottom>
                  &quot;Humans were special and important because up until now they were the most sophisticated data processing system in the
                  universe, but this is no longer the case.&quot; - Yuval Noah Harari
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={4}>
              <FlexGrowCol>
                <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                  A new philosophy in the era of big data.
                </Typography>
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexGrowCol>
                <Typography variant="body1" gutterBottom textAlign="left">
                  Dataism is often described as a new philosophy in the wake of the era of Big Data.
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  Some argue that dataism includes the belief that the value of an entity comes from its ability to contribute to data processing.
                  Others view political and social structures as dataist entities, too. In nearly every case, Dataism is described as the belief that
                  big data, and the analysis of that data, will be one of the most integral parts in the quest towards thoughtful innovation and
                  futuristic invention.
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  At XY Labs, the ideals behind Dataism drive our own innovation and product creation.
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={6}>
              <FlexGrowCol>
                <Typography variant="body1" gutterBottom textAlign="left">
                  Everyday, humans are making data. More and more, we&apos;re using platforms, apps, and programs that contribute to huge pools of
                  data, and then return us curated and highly-customized content and services. We like to call those data pools &quot;Data
                  Oceans&quot;.
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  Data Oceans, when leveraged, can give humans a lot of potential. At XY, we aim to create products that leverage data oceans, and
                  make humans lives easier. We specialize in geospatial information.
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  In order to do this, XY incorporates ideals of Dataism, and combines them with our own beliefs of data ownership and responsibility.
                  We believe that humans have a right to own their data, choose who can view and utilize that data, and ultimately decide how they
                  wish to exchange for that data.
                </Typography>
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={4}>
              <FlexGrowCol>
                <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                  Dataism and XY Labs
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={4}>
              <FlexGrowCol>
                <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                  Data as a Currency
                </Typography>
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexGrowCol>
                <Typography variant="body1" gutterBottom textAlign="left">
                  Simply put, XY considers data a currency. We&apos;re transparent about location data we use in our products and projects, and create
                  apps that allow users to opt-in to goods and services they feel comfortable with.
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={6}>
              <FlexGrowCol>
                <Typography variant="body1" gutterBottom textAlign="left">
                  While we&apos;re not sure how Dataism will continue to be adopted, we can say that we&apos;re excited to see how XY Labs will
                  continue to grow and create products that give users a dataist future and lifestyle.
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="left">
                  If you’re reading this, and you&apos;re excited about the future of big data, data ownership, and how you can be a part of it, we
                  encourage you to check out our products and become a part of the XY Family.
                </Typography>
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={4}>
              <FlexGrowCol>
                <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                  Looking towards the future
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default DataismPage
