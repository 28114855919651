import { alpha, Card, CardActions, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ButtonEx, FlexCol, FlexGrowRow } from '@xylabs/sdk-react'
import React from 'react'

import FilingDataProps from './FilingDataProps'

interface FilingListProps extends FilingDataProps {
  dark?: boolean
}

const FilingCard: React.FC<FilingListProps> = (props) => {
  const { date, link, title, type, dark = false } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <FlexCol justifyContent="flex-start" alignItems="stretch" margin={0.5}>
      <Card
        elevation={0}
        style={{
          backgroundColor: dark ? undefined : alpha(theme.palette.primary.main, 0.05),
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={5} style={{ marginBottom: 'auto', marginTop: 'auto' }}>
              <Typography variant="h6" textAlign="left" gutterBottom={isMobile ? true : false}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={1} style={{ marginBottom: 'auto', marginTop: 'auto' }}></Grid>
            <Grid item xs={12} md={2} style={{ marginBottom: 'auto', marginTop: 'auto' }}>
              <Typography variant="body1" textAlign="left">
                <Typography variant="body1" component="span" textAlign="left">
                  Type:
                </Typography>{' '}
                {type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginBottom: 'auto', marginTop: 'auto' }}>
              <Typography variant="body1" textAlign={isMobile ? 'left' : 'right'}>
                {date}
              </Typography>
            </Grid>
            {isMobile ? null : (
              <Grid item xs={12} sm={1} md={2} style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                <FlexGrowRow justifyContent="flex-end">
                  <ButtonEx href={link}>View</ButtonEx>
                </FlexGrowRow>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {isMobile ?
          <CardActions>
            <FlexGrowRow justifyContent="flex-end">
              <ButtonEx href={link}>View</ButtonEx>
            </FlexGrowRow>
          </CardActions>
        : null}
      </Card>
    </FlexCol>
  )
}

export default FilingCard
