import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { BasicHero } from '../../common'
import BasePage from '../BasePage'
import {
  SaleTerms1,
  SaleTerms2,
  SaleTerms3,
  SaleTerms4,
  SaleTerms5,
  SaleTerms6,
  SaleTerms7,
  SaleTerms8,
  SaleTerms9,
  SaleTerms10,
  SaleTerms11,
  SaleTerms12,
  SaleTerms13,
  SaleTerms14,
  SaleTerms15,
  SaleTerms16,
  SaleTerms17,
  SaleTerms18,
  SaleTerms19,
} from './img'

const SaleTermsPage: React.FC = () => {
  return (
    <BasePage name="Sales Terms">
      <BasicHero title="Sales Terms" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            <img src={SaleTerms1} width="100%" />
            <img src={SaleTerms2} width="100%" />
            <img src={SaleTerms3} width="100%" />
            <img src={SaleTerms4} width="100%" />
            <img src={SaleTerms5} width="100%" />
            <img src={SaleTerms6} width="100%" />
            <img src={SaleTerms7} width="100%" />
            <img src={SaleTerms8} width="100%" />
            <img src={SaleTerms9} width="100%" />
            <img src={SaleTerms10} width="100%" />
            <img src={SaleTerms11} width="100%" />
            <img src={SaleTerms12} width="100%" />
            <img src={SaleTerms13} width="100%" />
            <img src={SaleTerms14} width="100%" />
            <img src={SaleTerms15} width="100%" />
            <img src={SaleTerms16} width="100%" />
            <img src={SaleTerms17} width="100%" />
            <img src={SaleTerms18} width="100%" />
            <img src={SaleTerms19} width="100%" />
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default SaleTermsPage
