import { ThemeOptions } from '@mui/material'

export const darkThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: '#f7f8fc',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#0b0f30',
      paper: '#101742',
    },
    mode: 'dark',
    text: {
      disabled: '#a5acdb',
      primary: '#f7f8fc',
      secondary: '#daddf2',
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 12,
  typography: {
    fontWeightRegular: 400,
  },
}
