import { Typography } from '@mui/material'
import { FlexCol } from '@xylabs/sdk-react'

import * as TeamPhotos from './img'
import TeamMemberProps from './teamMemberProps'
const teamData: TeamMemberProps[] = [
  {
    bio: [
      'Arie is Co-Founder of XYO Network, the world’s first blockchain geospatial oracle network backed with cryptography. Arie is an accomplished serial entrepreneur with a rich history of technological breakthroughs and business successes involving multiple 8-figure exit events. Arie founded XY Labs in 2012 and is a strong believer in decentralization and the creation of the integrated owner/user model.',

      'He currently serves as Chief Executive Officer and Chairman of the Board of Directors at XY Labs, Inc. At XY, he also heads the XYO Foundation, which is responsible for the open source development of the XYO Network and the promotion of the XYO Token.',

      'Prior to starting XY Labs, Arie was CEO and Chairman of Pike Holdings. He received his Bachelor of Science in Computer Science from the New York Institute of Technology.',
    ],
    image: TeamPhotos.ArieTrouw,
    motto: 'The one man who dreams solely in XY Finder beeps and floating binary code.',
    name: 'Arie Trouw',
    role: 'Founder & CEO',
  },
  {
    bio: [
      'Markus is the Co-Founder of XYO, the world’s first blockchain geospatial oracle network backed with cryptography that anonymously collects and validates data with a geographic component. He also serves as Head of Operations for XYO’s parent company XY Labs. XY Labs creates products and services that leverage data to make human lives easier. For example, the COIN App, by XY Labs, empowers its over 3 million users to be rewarded for validating geospatial location and other data.  ',

      'Markus Levin has more than 15 years experience in building, growing and selling companies in hyper-growth industries around the globe. Markus focuses on maximizing institutional potential through the implementation of innovative data-driven solutions. Markus mined his first Bitcoin in 2013 and has been captivated by blockchain technologies ever since.',
    ],
    image: TeamPhotos.MarkusLevin,
    motto: "What's Markus' favorite catchphrase? 'Awesome Awesome.'",
    name: 'Markus Levin',
    role: 'Head of Operations',
  },
  {
    bio: [
      'John joined XY as Head of Innovation in late 2018, bringing with him a strong background in product and technology. He is a self-taught developer who started programming in his early teens and has been at it ever since. He has served as CTO and CPO for several tech companies and has extensive experience running R&D departments responsible for creating products that have been exposed to millions of users around the world.',
    ],
    image: TeamPhotos.JohnArana,
    motto: 'The Man. The Myth. The Musician.',
    name: 'John Arana',
    role: 'Head of Innovation',
  },
  {
    bio: [
      'Christine has an extensive background in Mathematics and has worked in upper management for over 8 years. She specializes in data structuring and analysis to help XY monitor the health of its business and make critical operational decisions. Data-centric and obsessed with organization, she also makes sure the day-to-day operations of the company run smoothly and facilitates many of the finance, sales, and investment-related endeavors.',

      'She moved to sunny San Diego to escape the Midwest tundra in 2012 after graduating with a Bachelors of Science in Mathematics from the University of Minnesota, Twin Cities. Christine is also a local musician and can be seen playing around San Diego in board shorts and a tank-top.',

      'Fun fact: Her nicknamed job title is ""Head of Context,"" as she somehow seems knows more about every situation than any normal person should.',
    ],
    image: TeamPhotos.ChristineSako,
    motto: "'If something is impossible, Christine will have it done in 5 mins' - Markus Levin",
    name: 'Christine Sako',
    role: 'Head of Internal Operations and BI',
  },
  {
    bio: [
      'Joseph started programming at 6 years old on an Apple][ and his excitement working with computers, networks, and electronics has never stopped.',
      'Over the years he has worked on various projects and technologies including:',
      <FlexCol key={2} alignItems="flex-start">
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Blockchain crypto location mobile software development
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Analytic big data software development
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Mobile and desktop software development
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Location based software and hardware development
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Game server administration and client software development
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;VoIP network design - hardware and software
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Web-based application projects, Co-located server administration
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;E commerce consulting, Custom programming
        </Typography>
        <Typography paddingLeft={2} variant="body2" gutterBottom>
          &nbsp;·&nbsp;Internet network services research, design, analysis and consultation
        </Typography>
        <Typography paddingLeft={2} variant="body2">
          &nbsp;·&nbsp;Network Operations for Domain, Dialup, ISDN, Frame, T1 customers
        </Typography>
      </FlexCol>,
      'When not working he enjoys exploring a never ending list of new hobbies: computers, video games, musical instruments, skateboarding, windsurfing, skiing, snowboarding, hiking, surfing, biking, gardening, weightlifting, painting, food, cars, movies, travel ...',
    ],
    image: TeamPhotos.JoeBader,
    motto: 'Some say Joe knows Android almost as good as the illuminati knows Joe.',
    name: 'Joe Bader',
    role: 'Lead Innovation Engineer',
  },
  {
    bio: [
      "Jordan has worn nearly every hat at XY. She's been working at XY each summer since 2015, and started full-time in 2018! During that time, she's been involved with helping the Customer Experience Team, Fulfillment, Social Media, Advertising, Web Development, and has finally settled in a Product Management role. While still in high school, she helped give XY Website UX tips, and helped run booths at WonderCon in Anaheim, CA. As Arie's daughter, she has QA'd his projects since childhood, and has enjoyed getting to learn the ins and outs of company management and growth!",

      "Jordan received her Bachelor's degree from the University of Chicago in 2018. Fun Fact: Jordan is an avid gamer, with her all-time favorite being World of Warcraft! At the moment, she's been playing a lot of Red Dead Redemption 2 and Zelda: Breath of the Wild.",
    ],
    image: TeamPhotos.JordanTrouw,
    name: 'Jordan Trouw',
    role: 'Head of Customer Experience',
  },
  {
    bio: [
      'Josh is a data-obsessed performance marketing expert with extensive experience managing large UA budgets across a variety of channels. A self-starter, he has been at the forefront of several successful global mobile app releases and their ongoing UA support.',

      "A native Washingtonian: Josh is fond of Excel memes, music, and the outdoors. In his free time, you're equally as likely to find him jamming in his studio as you are to catch him riding his bike around the Puget Sound.",
    ],
    image: TeamPhotos.JoshuaJensen,
    name: 'Joshua Jensen',
    role: 'User Acquisition Manager',
  },
  {
    bio: ['Bio Coming Soon!'],
    image: TeamPhotos.LinLee,
    motto: "Don't kill Lin's home-grown raddish... or you may not get that business expense approved...",
    name: 'Lin Lee',
    role: 'Accountant',
  },
  {
    bio: [
      "Maryann is XY's longest-tenured employee, except for Arie, himself. She started on the Webble project in 2015, and now works as a product manager on the development team. The only things she loves as much as XY are Disney, the Elder Scrolls series, and all things gothic.",
    ],
    image: TeamPhotos.MaryannCummings,
    motto: 'Maryann kicks butt and takes names… and then tracks them with an XY Finder',
    name: 'Maryann Cummmings',
    role: 'Head of XYO Operations',
  },
  {
    bio: [
      'Ricky brings his process engineering skills to XYO. He went to City College and UCSD, studying Software Engineering.',

      'Ricky founded the app "Foupon", an app that locates coupons for restaurants locally, in 2018.',
    ],
    image: TeamPhotos.RicardoAlcazar,
    motto: 'Baler? I hardly know her!',
    name: 'Ricardo Alcazar',
    role: 'Production Engineer',
  },
  {
    bio: [
      'Ryan began coding Java in his AP Computer Science course in High School and throughout college. After graduation, he began focusing on web technologies as it enabled him to distribute the software he was creating to a wider audience. Prior to starting at XY, Ryan built a static merchandising site generator for professional athletes. The most noteworthy athletes including Richard Sherman, Odell Beckham Jr, Shannon Sharpe, and Barry Sanders.',

      'As a passionate learner, he worked at the UCSD extension teaching JavaScript and web development to aspiring developers. Along with teaching he gives back to the community by developing libraries and enjoys contributing to the open source web.',

      'He prides himself on writing maintainable and reusable code, constantly learning and finding new tools to improve development workflows and user experience. With his deep full stack knowledge he adapts quickly and solves challenges effectively. Ryan is eager to tackle the problems that new technology inevitably brings and is excited to pioneer this trail with the XY Team!',
    ],
    image: TeamPhotos.RyanHansen,
    motto: 'Ryan codes 24 / 7. His brain codes during the day... and his beard codes during the night.',
    name: 'Ryan Hansen',
    role: 'Senior Software Engineer',
  },
  {
    bio: [
      'Tyler is devoted to creating an exceptional and entirely convenient approach to our Customer Experience team with his genuine and easily excitable positivity.',

      'His initial exposure to XYO occurred at the first annual Spatial event where he fell in love with the vision and culture of the company and wanted nothing more than his own involvement. He strives to learn as much as possible and become a great mentor for XYO.',

      "In his free time, you can find Tyler playing San Diego's golf courses or hitting up ski resorts.",
    ],
    image: TeamPhotos.TylerHill,
    motto: "XY's resident 80's glam-rocker.",
    name: 'Tyler Hill',
    role: 'COIN Support Manager',
  },
  {
    image: TeamPhotos.PhoebeDog,
    motto: "AKA 'Biggest Killer of Productivity' and 'Distraction in Chief'",
    name: 'Phoebe the Dog',
    role: 'Head of (Virtual) Office Excitement',
  },
  {
    bio: [
      "When he's not in the XY warehouse, Ardie competes nationally in bodybuilding! (He's qualified in the Men's Physique division). In his words, 'The gym &training is a passion and my therapy.' He's also a certified personal trainer and a certified nutrition specialist!",

      'Ardie was born and raised in sunny San Diego, and on his cheat days, he loves to spend time with his German Shepherd Nalani!',
    ],
    motto: "A bodybuilder walks into a bar. 'Ouch,' says the bar.",
    name: 'Ardie Loyola',
    role: 'Warehouse Associate',
  },
  {
    bio: ['Bio Coming Soon!'],
    image: TeamPhotos.ComingSoon,
    motto: '',
    name: 'Joel Carter',
    role: 'Lead XYO Protocol Engineer',
  },
  {
    bio: ['Bio Coming Soon!'],
    image: TeamPhotos.ComingSoon,
    motto: '',
    name: 'Matt Jones',
    role: 'Senior Software Engineer',
  },
]

export default teamData
