/* eslint-disable import/no-internal-modules */
import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import cloudMobile from './image/cloud-mobile.svg'
import clouds from './image/home-hero-clouds.svg'
import leftDots from './image/home-hero-left-dots.svg'
import rightDots from './image/home-hero-right-dots.svg'
import satellite from './image/home-hero-satellite.svg'
import stars from './image/home-hero-stars.svg'
import worldLeft from './image/home-hero-world-left.svg'
import worldRight from './image/home-hero-world-right.svg'
import worldLeftMobile from './image/left-world-mobile.svg'
import worldRightMobile from './image/right-world-mobile.svg'
import rocketMobile from './image/rocket-mobile.svg'
import satelliteMobile from './image/satellite-mobile.svg'
import starsLeftMobile from './image/stars-left-mobile.svg'
import starsRightMobile from './image/stars-right-mobile.svg'
import rightDotsMobile from './image/white-dot-right-mobile.svg'

export const HeroBackgroundParallax: React.FC = () => {
  return (
    <ParallaxBanner
      style={{ height: '100vh' }}
      layers={[
        {
          expanded: false,
          image: worldLeft,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, -100],
          translateY: [0, -100],
        },
        {
          expanded: false,
          image: worldRight,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 100],
          translateY: [0, -70],
        },
        {
          expanded: false,
          image: leftDots,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 100],
          translateY: [0, -100],
        },
        {
          expanded: false,
          image: rightDots,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, -100],
          translateY: [0, -70],
        },
        {
          expanded: false,
          image: clouds,
          shouldAlwaysCompleteAnimation: true,
          translateY: [10, 30],
        },
        {
          expanded: false,
          image: stars,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 15],
          translateY: [0, -15],
        },
        {
          expanded: false,
          image: satellite,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateY: [0, 25],
        },
      ]}
    />
  )
}

export const MobileHeroBackgroundParallax: React.FC = () => {
  return (
    <ParallaxBanner
      style={{ height: '100vh' }}
      layers={[
        {
          expanded: false,
          image: worldLeftMobile,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, -100],
          translateY: [0, -100],
        },
        {
          expanded: false,
          image: worldRightMobile,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 100],
          translateY: [0, -70],
        },
        {
          expanded: false,
          image: rightDotsMobile,
          scale: [1, 4],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 100],
          translateY: [0, -100],
        },
        {
          expanded: false,
          image: cloudMobile,
          shouldAlwaysCompleteAnimation: true,
          translateY: [10, 30],
        },
        {
          expanded: false,
          image: starsLeftMobile,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 15],
          translateY: [0, -15],
        },
        {
          expanded: false,
          image: starsRightMobile,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateX: [0, 15],
          translateY: [0, -15],
        },
        {
          expanded: false,
          image: satelliteMobile,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateY: [0, 25],
        },
        {
          expanded: false,
          image: rocketMobile,
          scale: [1, 2],
          shouldAlwaysCompleteAnimation: true,
          translateY: [0, 25],
        },
      ]}
    />
  )
}
