import { useContext } from 'react'

import Context from './Context'

const useSettings = () => {
  const settings = useContext(Context)
  return { ...settings }
}

export default useSettings
