import { Container, Grid, MobileStepper, Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const XyoQuote: React.FC = () => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

  return (
    <SectionSpacingRow bgcolor={theme.palette.primary.dark}>
      <Container>
        <Grid container alignContent="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={7000}
            >
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; I am hodling my XYO for when the crypto community and the rest of the world realizes the awesome potential XYO has in the
                  Geospatial arena and data utilization. I plan to retire a crypto millionaire sooner rather than later! &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - Carina F, Medical Laboratory Scientist (Michigan, US)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; I initially was using it for side money but I ended up holding for the longest time. When the price jumped I sold to reinvest
                  in other opportunities. Now my investment portfolio is up near 20,000 dollars. &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - Joshua S (Virginia, US)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; I&apos;ve been able to buy a car, a month&apos;s fuel and a month&apos;s rent using XYO. AND I still have £3000 worth in my
                  wallet. &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - Gary Jones (United Kingdom)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; ...my participation with COIN (and XYO) has quite literally resulted in life altering, positive changes. For example, I am 5
                  years in on a 30-year mortgage and through COIN/XYO I could pay it all off tomorrow if I wanted to.&quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - T.T., Software Engineer (New Hampshire, US)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; ...my participation with COIN (and XYO) has quite literally resulted in life altering, positive changes. For example, I am 5
                  years in on a 30-year mortgage and through COIN/XYO I could pay it all off tomorrow if I wanted to.&quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - T.T., Software Engineer (New Hampshire, US)
                </Typography>
              </FlexCol>
            </AutoPlaySwipeableViews>
            <FlexRow justifyContent="center">
              <MobileStepper
                steps={4}
                style={{ backgroundColor: 'transparent' }}
                position="static"
                activeStep={activeStep}
                nextButton={null}
                backButton={null}
              />
            </FlexRow>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default XyoQuote
