import { Divider } from '@mui/material'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'
import { ProductsCarousel } from './ProductsCarousel'

export const ProductsPage: React.FC = () => {
  return (
    <BasePage name="Products">
      <BasicHero
        title="Our Products"
        desc="Location data, blockchain, tracking items, data provenance &amp; certainty... whatever it is, we got it!"
        backgroundImage={CommonImages.floorPlantsWide}
        centeredAlways
      />
      <Divider />
      <ProductsCarousel />
    </BasePage>
  )
}
