import { Container, Divider, Grid, Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow, useIsMobile } from '@xyo-network/react-shared'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'

const HistoryPage: React.FC = () => {
  const isMobile = useIsMobile()
  return (
    <BasePage name="History">
      <BasicHero title="Our History" desc="From our San Diego foundation to today." backgroundImage={isMobile ? '' : CommonImages.booksRight} />
      <Divider />
      <SectionSpacingRow>
        <Container>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <FlexGrowCol width="100%" alignItems="flex-start">
                <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
                  2012 - Today
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  XY Labs was founded in 2012 by Arie Trouw. The company began as an idea to create a hyper-location network that could connect the
                  real world with virtual realities through Internet of Things (IoT) devices.
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  XY&apos;s experience with extensive consumer hardware, community, and support, combined with our vision of persistency and evolution
                  in the face of changing markets has allowed XY Labs to take the steps to becoming an expansive company with a broad variety of tools
                  and technologies to change the user&apos;s experience and interactions with the physical world.
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default HistoryPage
