import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { BasicHero } from '../../common'
import BasePage from '../BasePage'
import {
  PrivacyPolicy1,
  PrivacyPolicy2,
  PrivacyPolicy3,
  PrivacyPolicy4,
  PrivacyPolicy5,
  PrivacyPolicy6,
  PrivacyPolicy7,
  PrivacyPolicy8,
  PrivacyPolicy9,
  PrivacyPolicy10,
  PrivacyPolicy11,
  PrivacyPolicy12,
  PrivacyPolicy13,
  PrivacyPolicy14,
  PrivacyPolicy15,
} from './img'

const TermsPage: React.FC = () => {
  return (
    <BasePage name="Privacy">
      <BasicHero title="Privacy Policy" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            <img src={PrivacyPolicy1} width="100%" />
            <img src={PrivacyPolicy2} width="100%" />
            <img src={PrivacyPolicy3} width="100%" />
            <img src={PrivacyPolicy4} width="100%" />
            <img src={PrivacyPolicy5} width="100%" />
            <img src={PrivacyPolicy6} width="100%" />
            <img src={PrivacyPolicy7} width="100%" />
            <img src={PrivacyPolicy8} width="100%" />
            <img src={PrivacyPolicy9} width="100%" />
            <img src={PrivacyPolicy10} width="100%" />
            <img src={PrivacyPolicy11} width="100%" />
            <img src={PrivacyPolicy12} width="100%" />
            <img src={PrivacyPolicy13} width="100%" />
            <img src={PrivacyPolicy14} width="100%" />
            <img src={PrivacyPolicy15} width="100%" />
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default TermsPage
