import { SimpleCardProps } from '@xyo-network/react-card'

import { CommonLogos } from '../../common'
import * as MediaImages from './articleArt'
interface NewsArticleProps extends SimpleCardProps {
  date?: string
  image?: string
  logoDark?: string
  logoLight?: string
}

const NewsData: NewsArticleProps[] = [
  {
    date: 'September 28, 2022',
    headline: 'XY Labs Announces 2022 Semi-Annual Financial Report',
    href: 'https://www.businesswire.com/news/home/20220927006149/en/XY-Labs-Announces-2022-Semi-Annual-Financial-Report?_gl=1*1qqx8ib*_ga*NDgwMTgwODA3LjE2NjM1MjkxNjU.*_ga_ZQWF70T3FK*MTY2NDM2ODMzNy45LjEuMTY2NDM2OTc3MS4wLjAuMA',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.techHand,
  },
  {
    date: 'September 14, 2022',
    headline: "Blockchain oracle network XY Labs' shares start trading at SEC-registered platform",
    href: 'https://cointelegraph.com/news/blockchain-oracle-network-xy-labs-shares-start-trading-at-sec-registered-platform',
    logoDark: CommonLogos.cointelegraphDark,
    logoLight: CommonLogos.cointelegraphLight,
    media: MediaImages.chart,
  },
  {
    date: 'September 14, 2022',
    headline: 'Happening Now: Trade Shares of XY Labs on tZERO ATS!',
    href: 'https://medium.com/xyonetwork/happening-now-trade-shares-of-xy-labs-on-tzero-ats-3d36a986476f',
    logoDark: CommonLogos.cointelegraphDark,
    logoLight: CommonLogos.cointelegraphLight,
    media: MediaImages.tzero,
  },
  {
    date: 'September 14, 2022',
    headline: "XY Labs' Class A Common Stock Begins Trading on the tZERO ATS",
    href: 'https://finance.yahoo.com/news/xy-labs-class-common-stock-123000245.html',
    logoDark: CommonLogos.cointelegraphDark,
    logoLight: CommonLogos.cointelegraphLight,
    media: MediaImages.stocks,
  },
  {
    date: 'November 19, 2021',
    headline: 'XYO Exclusive Campaign - Win a Share of USD 50,000!',
    href: 'https://blog.crypto.com/xyo-exclusive-campaign/',
    logoDark: CommonLogos.cryptocomLogoDark,
    logoLight: CommonLogos.cryptocomLogoLight,
    media: MediaImages.cryptocom,
  },
  {
    date: 'November 10, 2021',
    headline: 'XYO Now Live on BOTH Crypto.com and Crypto.com App',
    href: 'https://medium.com/xyonetwork/breaking-xyo-now-live-on-both-crypto-com-and-crypto-com-app-2f8e85c696f0',
    logoDark: CommonLogos.XYOLogoDark,
    logoLight: CommonLogos.XYOLogoLight,
    media: MediaImages.cryptocom2,
  },
  {
    date: 'October 07, 2021',
    headline: 'tZERO Joins Forces With XY Labs, the Company Behind XYO, to Digitize & Trade XY Labs Equity',
    href: 'https://www.businesswire.com/news/home/20211007005168/en/tZERO-Joins-Forces-With-XY-Labs-the-Company-Behind-XYO-to-Digitize-Trade-XY-Labs-Equity',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.tzero,
  },
  {
    date: 'October 04, 2021',
    headline: "XYO Network Using Chainlink VRF to Fairly Select Random Winners of COIN's Location-Based Giveaways",
    href: 'https://medium.com/xyonetwork/xyo-network-using-chainlink-vrf-to-fairly-select-random-winners-of-coins-location-based-giveaways-91b665a42dd8',
    logoDark: CommonLogos.XYOLogoDark,
    logoLight: CommonLogos.XYOLogoLight,
    media: MediaImages.chainlink,
  },
  {
    date: 'September 28, 2021',
    headline: 'XY Labs / XYO Network Announces Record Profits for the First Half of 2021 With 2x Growth',
    href: 'https://www.businesswire.com/news/home/20210928006178/en/XY-Labs-XYO-Network-Announces-Record-Profits-for-the-First-Half-of-2021-With-2x-Growth',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.Profit2021,
  },
  {
    date: 'September 10, 2021',
    headline: 'Rari Governance Token (RGT) and XYO Network (XYO) are now available on Coinbase',
    href: 'https://blog.coinbase.com/rari-governance-token-rgt-and-xyo-network-xyo-are-now-available-on-coinbase-df454bef4526',
    logoDark: CommonLogos.coinbaseLogoDark,
    logoLight: CommonLogos.coinbaseLogoLight,
    media: MediaImages.coinbase,
  },
  {
    date: 'June 08, 2021',
    headline: 'XY/XYO/COIN 2021 Q2 Update',
    href: 'https://medium.com/xyonetwork/xy-xyo-coin-2021-q2-update-ca31c1e9acb0',
    logoDark: CommonLogos.XYOLogoDark,
    logoLight: CommonLogos.XYOLogoLight,
    media: MediaImages.q2,
  },
  {
    date: 'October 18, 2019',
    headline: 'COIN Will Soon Be Powered by Its Own Native Digital Currency Called… COIN!',
    href: 'https://medium.com/coinapp/exciting-major-update-coin-will-soon-be-powered-by-its-own-native-digital-currency-called-coin-7448b263b335',
    logoDark: CommonLogos.coinDark,
    logoLight: CommonLogos.coinLight,
    media: MediaImages.coins,
  },
  {
    date: 'April 17, 2019',
    headline: 'Arie Trouw Accepted into Forbes Technology Council',
    href: 'https://www.businesswire.com/news/home/20190417005052/en/Arie-Trouw-Accepted-into-Forbes-Technology-Council',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.forbes,
  },
  {
    date: 'February 20, 2019',
    headline: 'XYO Wins Big at MOBI Grand Challenge Hackathon in Munich',
    href: 'https://www.businesswire.com/news/home/20190220005516/en/XYO-Wins-Big-at-MOBI-Grand-Challenge-Hackathon-in-Munich',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.techHand,
  },
  {
    date: 'February 06, 2019',
    headline: 'XYO Debuts Killer “COIN” App; Now Available for Geomining Kit Owners on iOS and Android',
    href: 'https://www.businesswire.com/news/home/20190206005715/en/XYO-Debuts-Killer-%E2%80%9CCOIN%E2%80%9D-App-Now-Available-for-Geomining-Kit-Owners-on-iOS-and-Android',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.coinapp,
  },
  {
    date: 'October 05, 2018',
    headline: 'XY — The Findables Company Announces 2018 Semi-Annual Financial Report and Results',
    href: 'https://www.businesswire.com/news/home/20181005005103/en/XY-%E2%80%93-The-Findables-Company-Announces-2018-Semi-Annual-Financial-Report-and-Results',
    logoDark: CommonLogos.businesswireDark,
    logoLight: CommonLogos.businesswireLight,
    media: MediaImages.chart,
  },
  {
    date: 'October 16, 2016',
    headline: 'XY Findables Opens for Investment on StartEngine Seeking $10 Million',
    href: 'https://www.crowdfundinsider.com/2016/10/91255-xy-findables-opens-investment-startengine-seeking-10-million/',
    logoDark: CommonLogos.cointelegraphDark,
    logoLight: CommonLogos.cointelegraphLight,
    media: MediaImages.money,
  },
]

export default NewsData
