import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { ButtonEx, FlexGrowCol } from '@xylabs/sdk-react'
import * as React from 'react'

import TeamMemberProps from './teamMemberProps'

const TeamBioDialog: React.FC<TeamMemberProps> = (props) => {
  const { role, name, motto, bio } = props
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonEx variant="contained" onClick={handleClickOpen}>
        Read Bio
      </ButtonEx>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4">{name}</Typography>
          <Typography variant="subtitle2">{role}</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {motto ?
            <Typography variant="body2" color="inherit" gutterBottom paddingBottom={1}>
              {motto}
            </Typography>
          : null}
          <FlexGrowCol alignItems="flex-start">
            {bio?.map((text, index) => {
              return (
                <Typography key={index} variant="body1" gutterBottom>
                  {text}
                </Typography>
              )
            })}
          </FlexGrowCol>
        </DialogContent>
        <DialogActions>
          <ButtonEx onClick={handleClose}>Close</ButtonEx>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TeamBioDialog
