import { FunnelStartedFields, TestStartedFields, UserClickFields, UserEventHandler, ViewContentFields } from '@xylabs/pixel'
import { GoogleCartProperties } from '@xylabs/sdk-react'

import CustomProperties from './CustomProperties'
import GoogleEvents from './Google'

class GoogleEventHandler<T extends Record<string, unknown>> extends UserEventHandler<T> {
  private events = new GoogleEvents<CustomProperties | GoogleCartProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send(fields)
  }
}

export default GoogleEventHandler
