import { Typography, useTheme } from '@mui/material'
import { forget } from '@xylabs/sdk-js'
import { BasePage, FlexCol, FlexGrowCol, FlexRow, isLocalhost, RedirectWithQuery } from '@xylabs/sdk-react'
import { useLocation } from 'react-router-dom'

import { AppBar } from '../AppBar'
import { useSettings } from '../Contexts'
import { Footer } from '../Footer'
import { useUserEvents } from '../hooks'
import { BasePageProps } from './BasePageProps'

const XyBasePage: React.FC<BasePageProps> = ({
  devOnly = false,
  devOnlyTo = false,
  localAsDev = false,
  scrollToTopButton,
  name,
  appBar,
  positioningAppbar = true,
  appFooter,
  children,
  ...props
}) => {
  const { developerMode } = useSettings()
  const userEvents = useUserEvents()
  const location = useLocation()
  const theme = useTheme()
  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)

  forget(userEvents.viewContent({ name, path: location.pathname }))

  return (
    <BasePage
      scrollToTopButton={scrollToTopButton ?? true}
      appBar={appBar ?? <AppBar position="fixed" elevation={0} />}
      appFooter={
        appFooter ?? (
          <FlexCol alignItems="stretch">
            <Footer />
          </FlexCol>
        )
      }
      {...props}
    >
      <FlexGrowCol alignItems="stretch">
        {/* This is here to provide spacing under fixed header*/}
        {positioningAppbar ?
          <AppBar sx={{ opacity: 0, paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }} />
        : null}
        {devOnly && devRequirementPassed ?
          <FlexRow margin={1}>
            <Typography variant="body1">
              Important: This page is a Developer Only page. It is possible that some information may not be correct.
            </Typography>
          </FlexRow>
        : null}
        {devOnly && !devRequirementPassed ?
          <RedirectWithQuery to="/" />
        : null}
        {children}
      </FlexGrowCol>
    </BasePage>
  )
}

export default XyBasePage
