import { Person } from '@xylabs/sdk-react'

import { XyLabsOrganizationPartial } from './Organization'
import { XyLabsPostalAddress } from './PostalAddress'

export const ArieTrouwPersonPartial: Person = {
  '@type': 'Person',
  birthDate: new Date('November, 19, 1969').toISOString(),
  birthPlace: 'Pretoria, South Africa',
  children: [
    {
      '@type': 'Person',
      familyName: 'Trouw',
      gender: 'female',
      givenName: 'Jordan Elizabeth',
      name: 'Jordan Elizabeth Trouw',
    },
    {
      '@type': 'Person',
      familyName: 'Trouw',
      gender: 'female',
      givenName: 'Elise Ashlyn',
      name: 'Elise Ashlyn Trouw',
    },
  ],
  familyName: 'Trouw',
  gender: 'male',
  givenName: 'Arie',
  height: '76 inches',
  image: 'https://arietrouw.com/assets/img/arie.jpg',
  jobTitle: 'CEO/Founder',
  name: 'Arie Trouw',
  nationality: {
    '@type': 'Place',
    name: 'United States of America',
  },
  sameAs: [
    'https://twitter.com/arietrouw',
    'https://www.linkedin.com/in/arietrouw/',
    'https://www.facebook.com/arietrouw',
    'https://arietrouw.medium.com/',
  ],
  spouse: {
    '@type': 'Person',
    familyName: 'Trouw',
    gender: 'female',
    givenName: 'Anne Marie',
    name: 'Anne Marie Trouw',
  },
  url: 'https://arietrouw.com',
}

export const ArieTrouwPersonFull: Person = {
  workLocation: XyLabsPostalAddress,
  worksFor: XyLabsOrganizationPartial,
  ...ArieTrouwPersonPartial,
}
