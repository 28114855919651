import { Divider } from '@mui/material'

import { BasicHero, CommonImages } from '../../common'
import { OrganizationRichResult } from '../../RichResults'
import BasePage from '../BasePage'
import CompanyMission from './Mission'
import Team from './Team'

const Page: React.FC = () => {
  return (
    <BasePage name="Company">
      <OrganizationRichResult />
      <BasicHero
        title="Meet XY Labs"
        desc="From marketing to customer support to operations, we love to improve our products with a data-first mindset."
        button1Text="Our Mission"
        button1To="/company/mission"
        button2To="/company/team"
        button2Text="Our Team"
        backgroundImage={CommonImages.floorPlantsWide}
        centeredAlways
      />
      <Divider />
      <CompanyMission />
      <Team />
    </BasePage>
  )
}

export default Page
