import {
  ArticleRounded,
  AutoGraphRounded,
  ContactSupportRounded,
  DashboardRounded,
  FlagRounded,
  GroupsRounded,
  HubRounded,
  InfoRounded,
  LineAxisRounded,
  MonetizationOnRounded,
  MoneyRounded,
  NewspaperRounded,
  PaletteRounded,
  QuizRounded,
  RestoreRounded,
  TipsAndUpdatesRounded,
  TravelExploreRounded,
} from '@mui/icons-material'

import { NavbarSectionProps } from './NavbarDropdownDataProps'

export const navbarData: Array<NavbarSectionProps> = [
  {
    name: 'Company',
    sectionItems: [
      {
        desc: '',
        icon: (props) => <FlagRounded {...props} />,
        linkText: 'Company Overview',
        mobileOnly: true,
        to: '/company',
      },
      {
        desc: 'What we aim to do',
        icon: (props) => <FlagRounded {...props} />,
        linkText: 'Mission',
        to: '/company/mission',
      },
      {
        desc: 'Meet the team behind it all',
        icon: (props) => <GroupsRounded {...props} />,
        linkText: 'Team',
        to: '/company/team',
      },
      {
        desc: 'If I could turn back time...',
        icon: (props) => <RestoreRounded {...props} />,
        linkText: 'History',
        to: '/company/history',
      },
    ],
    to: '/company',
  },
  {
    name: 'Products',
    sectionItems: [
      {
        desc: '',
        icon: (props) => <FlagRounded {...props} />,
        linkText: 'Products Overview',
        mobileOnly: true,
        to: '/products',
      },
      {
        desc: "The World's First Reality Oracle",
        icon: (props) => <HubRounded {...props} />,
        linkText: 'XYO Network',
        to: '/products/xyo-network',
      },
      {
        desc: 'Explore the world & earn!',
        icon: (props) => <MonetizationOnRounded {...props} />,
        linkText: 'COIN',
        to: '/products/coin',
      },
      {
        desc: 'Keep the important stuff close',
        icon: (props) => <TravelExploreRounded {...props} />,
        linkText: 'XY Find It',
        to: '/products/xy-find-it',
      },
      {
        desc: 'What do we have in store?',
        icon: (props) => <TipsAndUpdatesRounded {...props} />,
        linkText: 'Future Products',
        to: '/products/future',
      },
    ],
    to: '/products',
  },
  {
    name: 'Investors',
    sectionItems: [
      {
        desc: '',
        icon: (props) => <FlagRounded {...props} />,
        linkText: 'Investors Overview',
        mobileOnly: true,
        to: '/investors',
      },
      {
        desc: "XY Labs' Log in & view your holdings",
        icon: (props) => <DashboardRounded {...props} />,
        linkText: 'Vertalo (Investor Portal)',
        to: '/investors/portal',
      },
      {
        desc: 'Buy, Sell, and Trade XY Labs Stock',
        icon: (props) => <LineAxisRounded {...props} />,
        linkText: 'tZERO Markets',
        to: '/investors/trade',
      },
      {
        desc: 'Get started here',
        icon: (props) => <InfoRounded {...props} />,
        linkText: 'Resources',
        to: '/investors/resources',
      },
      {
        desc: 'Annual SEC Filings & More',
        icon: (props) => <ArticleRounded {...props} />,
        linkText: 'Filings',
        to: '/filings',
      },
      {
        desc: 'Have questions? This may help.',
        icon: (props) => <QuizRounded {...props} />,
        linkText: 'FAQ',
        to: '/investors/faq',
      },
      {
        desc: "The ABC's of buying XY Stock",
        icon: (props) => <MoneyRounded {...props} />,
        linkText: 'How to Buy Equity',
        to: '/investors/how-to-buy',
      },
      {
        desc: 'Chat with our Investor Relations Team',
        icon: (props) => <ContactSupportRounded {...props} />,
        linkText: 'Support',
        to: '/investors/support',
      },
    ],
    to: '/investors',
  },
  {
    name: 'More',
    sectionItems: [
      {
        desc: 'What people say about us',
        icon: (props) => <NewspaperRounded {...props} />,
        linkText: 'In The News',
        to: '/news',
      },
      {
        desc: 'Brand logos, colors, & fonts',
        icon: (props) => <PaletteRounded {...props} />,
        linkText: 'Brand',
        to: '/brand',
      },
      {
        desc: 'Our thoughts on data & the future',
        icon: (props) => <AutoGraphRounded {...props} />,
        linkText: 'Dataism',
        to: '/dataism',
      },
    ],
    to: '',
  },
]
