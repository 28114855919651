import { Box, Paper, Switch, Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'

import { useSettings } from '../../Contexts'
import BasePage from '../BasePage'

const SettingsPage: React.FC = () => {
  const { darkMode, enableDarkMode, developerMode, enableDeveloperMode } = useSettings()

  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableDarkMode?.(event.target.checked)
  }

  const handleDeveloperModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableDeveloperMode?.(event.target.checked)
  }

  return (
    <BasePage name="Settings" container="xl">
      <FlexGrowCol padding={2} justifyContent="flex-start" alignItems="stretch">
        <Box padding={1}>
          <Paper variant="outlined">
            <Box alignItems="center" display="flex" justifyContent="space-between" margin={2}>
              <Typography variant="caption">Dark Mode</Typography>
              <Switch checked={darkMode} color="primary" onChange={handleDarkModeChange} />
            </Box>
          </Paper>
        </Box>
        <Box padding={1}>
          <Paper variant="outlined">
            <Box alignItems="center" display="flex" justifyContent="space-between" margin={2}>
              <Typography variant="caption">Developer Mode</Typography>
              <Switch checked={developerMode} color="primary" onChange={handleDeveloperModeChange} />
            </Box>
          </Paper>
        </Box>
      </FlexGrowCol>
    </BasePage>
  )
}

export default SettingsPage
