import { RedirectWithQuery } from '@xylabs/sdk-react'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { BrandPage } from './Brand'
import { CoinAppPage } from './CoinApp'
import { CompanyPage } from './Company'
import { DataismPage } from './Dataism'
import { FilingsPage } from './Filings'
import { FutureProjectsPage } from './FutureProjects'
import { GetStartedPage } from './GetStarted'
import { HistoryPage } from './History'
import { HomePage } from './Home'
import { MissionPage } from './Mission'
import { NewsPage } from './News'
import { PrivacyPage } from './Privacy'
import { ProductsPage } from './Products'
import { SalesTermsPage } from './SalesTerms'
import { SweepstakesRulesPage } from './SweepstakesRules'
import { TeamPage } from './Team'
import { TermsPage } from './Terms'
import { XyFinditPage } from './XyFindit'
import { XyoNetworkPage } from './XyoNetwork'

const InvestorRoutes = lazy(() => import(/* webpackChunkName: "investor" */ './InvestorRoutes'))
const JobsRoutes = lazy(() => import(/* webpackChunkName: "jobs" */ './JobsRoutes'))
const InvestorSuspense: React.FC = () => (
  <Suspense fallback={<div />}>
    <InvestorRoutes />
  </Suspense>
)
const JobsSuspense: React.FC = () => (
  <Suspense fallback={<div />}>
    <JobsRoutes />
  </Suspense>
)

export const SiteRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Jobs Pages */}
      <Route element={<JobsSuspense />} path="/jobs/*" />
      <Route element={<CompanyPage />} path="/company" />
      {/* Team Page & Redirect */}
      <Route element={<TeamPage />} path="/company/team" />
      <Route element={<RedirectWithQuery to="/company/team" />} path="/team" />
      {/* Mission Page & Redirect */}
      <Route element={<MissionPage />} path="/company/mission" />
      <Route element={<RedirectWithQuery to="/company/mission" />} path="/mission" />
      {/* History Page & Redirect */}
      <Route element={<HistoryPage />} path="/company/history" />
      <Route element={<RedirectWithQuery to="/company/history" />} path="/history" />
      {/* Roadmap Page & Redirect */}
      {/* <Route element={<RoadmapPage />} path="/company/roadmap" />
      <Route element={<RedirectWithQuery to="/company/roadmap" />} path="/roadmap" /> */}
      {/* Product Pages */}
      <Route element={<ProductsPage />} path="/products" />
      <Route element={<CoinAppPage />} path="/products/coin" />
      <Route element={<XyoNetworkPage />} path="/products/xyo-network" />
      <Route element={<XyFinditPage />} path="/products/xy-find-it" />
      <Route element={<FutureProjectsPage />} path="/products/future" />
      {/* Investor Pages & Redirects */}
      <Route element={<InvestorSuspense />} path="/investors/*" />
      {/* Legal Pages */}
      <Route element={<PrivacyPage />} path="/privacy" />
      <Route element={<TermsPage />} path="/terms" />
      <Route element={<SalesTermsPage />} path="/sales-terms" />
      <Route element={<SweepstakesRulesPage />} path="/sweeps" />
      {/* Other Pages */}
      <Route element={<DataismPage />} path="/dataism" />
      <Route element={<GetStartedPage />} path="/get-started" />
      <Route element={<BrandPage />} path="/brand" />

      <Route element={<FilingsPage />} path="/filings" />
      <Route element={<NewsPage />} path="/news" />
      <Route element={<HomePage />} path="/" />
      <Route element={<RedirectWithQuery to="/" />} path="/*" />
    </Routes>
  )
}
