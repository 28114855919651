import { Divider } from '@mui/material'
import { BasicHero } from '@xyo-network/react-shared'

import BasePage from '../BasePage'
import XyFinditExplained from './Explained'

const XyFinditPage: React.FC = () => {
  return (
    <BasePage name="XyFind It">
      <BasicHero title="Permanent Deprecation of XY Find It - January 2024" desc="" />
      <Divider />
      <XyFinditExplained />
      {/* <XyFinditQuote /> */}
    </BasePage>
  )
}

export default XyFinditPage
