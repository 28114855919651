import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { BasicHero } from '../../common'
import BasePage from '../BasePage'
import {
  Terms1,
  Terms2,
  Terms3,
  Terms4,
  Terms5,
  Terms6,
  Terms7,
  Terms8,
  Terms9,
  Terms10,
  Terms11,
  Terms12,
  Terms13,
  Terms14,
  Terms15,
  Terms16,
  Terms17,
} from './img'

const TermsPage: React.FC = () => {
  return (
    <BasePage name="Terms">
      <BasicHero title="Terms" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            <img src={Terms1} width="100%" />
            <img src={Terms2} width="100%" />
            <img src={Terms3} width="100%" />
            <img src={Terms4} width="100%" />
            <img src={Terms5} width="100%" />
            <img src={Terms6} width="100%" />
            <img src={Terms7} width="100%" />
            <img src={Terms8} width="100%" />
            <img src={Terms9} width="100%" />
            <img src={Terms10} width="100%" />
            <img src={Terms11} width="100%" />
            <img src={Terms12} width="100%" />
            <img src={Terms13} width="100%" />
            <img src={Terms14} width="100%" />
            <img src={Terms15} width="100%" />
            <img src={Terms16} width="100%" />
            <img src={Terms17} width="100%" />
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default TermsPage
