import { ListItemIcon, MenuItem, MenuList, Paper, Typography } from '@mui/material'
import { ButtonEx, FlexCol, LinkEx } from '@xylabs/sdk-react'
import { PropsWithChildren, SetStateAction, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSettings } from '../../Contexts'
import { NavBarItemProps, NavbarSectionProps } from './NavbarDropdownDataProps'

interface PopupProps {
  name: string
  open?: boolean
  setOpen: (value: boolean) => void
}

const CustomPopup: React.FC<PropsWithChildren<PopupProps>> = ({ setOpen, open, name, children }) => {
  useEffect(() => {
    const listener = () => setOpen(false)
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [open, setOpen])
  return (
    <div id={name + 'menu'} style={{ display: 'block', position: 'fixed', zIndex: 100 }}>
      {children}
    </div>
  )
}

interface NavBarMenuItemProps {
  index: number
  item: NavBarItemProps
  totalSectionItems: Array<NavBarItemProps>
}

const NavBarMenuItem: React.FC<NavBarMenuItemProps> = ({ item, index, totalSectionItems }) => {
  const [hovered, setHovered] = useState(false)
  const navigate = useNavigate()
  const darkMode = useSettings()

  const navigateMenuItem = (item: NavBarItemProps) => {
    if (item.to) {
      navigate(item.to)
    } else {
      window.open(item.href)
    }
  }

  return (
    <LinkEx color={hovered ? 'secondary' : 'inherit'} to={item.to ?? ''} href={item.href} gutterBottom target={item.href ? '_blank' : '_self'}>
      <MenuItem
        key={index}
        onClick={() => navigateMenuItem(item)}
        divider={index == totalSectionItems.length - 1 ? false : true}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ backgroundColor: 'transparent' }}
      >
        <ListItemIcon>
          {item.icon({
            color:
              hovered ?
                darkMode ? 'secondary'
                : 'primary'
              : 'inherit',
            fontSize: 'small',
          })}
        </ListItemIcon>
        <FlexCol alignItems="flex-start">
          <FlexCol alignItems="flex-start">
            <Typography variant="body2">{item.linkText}</Typography>
            <Typography variant="caption">{item.desc}</Typography>
          </FlexCol>
        </FlexCol>
      </MenuItem>
    </LinkEx>
  )
}

export const NavbarDropdown: React.FC<NavbarSectionProps> = ({ name, to, sectionItems }) => {
  const location = useLocation()
  const active = to && location.pathname.startsWith(to)
  const [open, setOpen] = useState(false)
  const darkMode = useSettings()

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <ButtonEx
        color={
          active ?
            darkMode ?
              'secondary'
            : 'primary'
          : 'inherit'
        }
        bgcolor="transparent"
        variant="text"
        padding={1}
        to={to}
        aria-controls={name + 'menu'}
        id={name + 'button'}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {name}
      </ButtonEx>
      {sectionItems && open ?
        <CustomPopup
          name={name}
          open={open}
          setOpen={(value: SetStateAction<boolean>) => {
            setOpen(value)
          }}
        >
          <Paper sx={{ boxShadow: 3 }}>
            <MenuList>
              {sectionItems.map((item, index) => {
                return item.mobileOnly === true ? null : <NavBarMenuItem key={index} index={index} item={item} totalSectionItems={sectionItems} />
              })}
            </MenuList>
          </Paper>
        </CustomPopup>
      : null}
    </div>
  )
}
