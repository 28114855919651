import { CheckCircleOutline, ErrorOutline, KeyboardArrowRightRounded } from '@mui/icons-material'
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material'
import { forget } from '@xylabs/forget'
import { ButtonEx } from '@xylabs/react-button'
import { FlexRow } from '@xylabs/react-flexbox'
import * as emailRegEx from 'email-regex-safe'
import { useState } from 'react'

import { iterableTrackEvent } from './Iterable'

export type EmailNewsletterTextFieldProps = TextFieldProps & {
  checkValidEmail?: boolean | null
  onEnter?: () => void
}

export const EmailNewsletterTextField: React.FC<EmailNewsletterTextFieldProps> = () => {
  const [customerEmail, setCustomerEmail] = useState<string | null>('')
  const [complete, setComplete] = useState(false)
  const [checkValidEmail, setCheckValidEmail] = useState<boolean | null>(null)

  const validEmailRegex = (email: string) => {
    console.log(`emailRegEx: ${typeof emailRegEx}`)
    return emailRegEx({ exact: true }).test(email)
  }

  const emailValidate = (value: string) => {
    if (validEmailRegex(value)) {
      setCheckValidEmail(true)
    } else {
      setCheckValidEmail(false)
    }
  }

  const handleChange = (email: string) => {
    if (email.length > 0) {
      setCustomerEmail(email)
      emailValidate(email)
    } else {
      setCustomerEmail('')
      emailValidate(email)
    }
  }
  return complete ?
      <Typography>Thank you for signing up!</Typography>
    : <FlexRow>
        <TextField
          variant="outlined"
          type="email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <CheckCircleOutline sx={{ display: checkValidEmail === null ? 'block' : 'hidden', visibility: 'hidden' }} />
                <CheckCircleOutline
                  color={'success'}
                  fontSize={'medium'}
                  sx={{ position: 'absolute', visibility: checkValidEmail === true ? 'visible' : 'hidden' }}
                />
                <ErrorOutline
                  color={'error'}
                  fontSize={'medium'}
                  sx={{ position: 'absolute', visibility: checkValidEmail === false ? 'visible' : 'hidden' }}
                />
              </InputAdornment>
            ),
          }}
          size="small"
          value={customerEmail ?? ''}
          onChange={(event) => handleChange?.(event.target.value)}
        />
        <ButtonEx
          color="secondary"
          marginLeft={2}
          variant="contained"
          endIcon={<KeyboardArrowRightRounded />}
          inputMode="email"
          onClick={() =>
            forget(
              (async () => {
                if (customerEmail && checkValidEmail) {
                  await iterableTrackEvent({
                    dataFields: {
                      url: window.location.href,
                    },
                    email: customerEmail?.toLocaleLowerCase(),
                    eventName: 'subscribeXyoNewsletter',
                  })
                  setComplete(true)
                }
              })(),
            )
          }
        >
          Sign Up
        </ButtonEx>
      </FlexRow>
}
