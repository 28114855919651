import { alpha, Container, Grid, Typography, useTheme } from '@mui/material'
import { BusyBoxProps, FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { SimpleCard, SimpleCardProps } from '@xyo-network/react-card'
import { SectionSpacingRow, useIsMobile } from '@xyo-network/react-shared'
import { ReactNode } from 'react'

interface DiscoverItem extends SimpleCardProps {
  desc: string
  icon?: ReactNode
  src?: string
  url?: string
}

export const GetStartedCards: React.FC<BusyBoxProps> = ({ ...props }) => {
  const itemList: DiscoverItem[] = [
    {
      desc: 'Learn more about the products XY Labs makes.',
      headline: 'XY Labs Products',
      url: '/products',
    },
    {
      desc: 'Meet the team, from our CEO to our Head of (Virtual) Excitement, Phoebe!',
      headline: 'XY Labs Team',
      url: '/company/team',
    },
    {
      desc: 'Interested in a career with XY Labs?',
      headline: 'How to work with XY',
      url: '/jobs',
    },
    {
      desc: 'Have questions about your investment, or want to become an investor?',
      headline: 'Investor FAQ & Resources',
      url: '/investors',
    },
    {
      desc: 'View XY Labs color palettes, logos, and font standards.',
      headline: 'Brand Colors, Logos, and Guidelines',
      url: '/brand',
    },
    {
      desc: 'Learn more about COIN, XYO, and XY Labs!',
      headline: 'Information about COIN & XY Labs',
      url: '/products/coin',
    },
    {
      desc: 'Need to chat with our Investor Relations team?',
      headline: 'Investor Support',
      url: '/investors/support',
    },
  ]
  const isMobile = useIsMobile()
  const FlexContainer = isMobile ? FlexCol : FlexRow
  const theme = useTheme()
  return (
    <SectionSpacingRow>
      <FlexCol alignItems="stretch" {...props}>
        <Container>
          <FlexContainer>
            <Grid container justifyContent="flex-start" alignItems="stretch" spacing={2}>
              {itemList.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={`btn-${index}`} alignItems="stretch">
                  <FlexCol height="100%">
                    <SimpleCard
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        backgroundColor: alpha(theme.palette.primary.main, 0.05),
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                      }}
                      headline={
                        <Typography variant="h6" fontWeight="Bold">
                          {item.headline}
                        </Typography>
                      }
                      interactionVariant="button"
                      desc={item.desc}
                      to={item.url}
                    />
                  </FlexCol>
                </Grid>
              ))}
            </Grid>
          </FlexContainer>
        </Container>
      </FlexCol>
    </SectionSpacingRow>
  )
}
