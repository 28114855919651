import { BoxProps, Typography } from '@mui/material'
import { FlexCol } from '@xylabs/sdk-react'

const Headquarters: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol {...props}>
      <Typography variant="h6">XY Labs</Typography>
      <Typography>1405 30th Street</Typography>
      <Typography>San Diego, CA 92154</Typography>
    </FlexCol>
  )
}

export default Headquarters
